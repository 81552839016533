import { useRef } from "react";
import ModalPop from "../../home/ModalPop";

const MobileQuick = () => {
  const popupRef = useRef<HTMLDivElement>(null);

  const openfunc = () => {
    popupRef.current!.style.display = "block";
  };

  const closefunc = () => {
    popupRef.current!.style.display = "none";
  };

  return (
    <div className="fixed right-0 bottom-0 w-full h-fit z-40 md:hidden">
      <div className="grid grid-cols-2 lg:block">
        <div
          onClick={() => (window.location.href = "tel:010-8330-8913")}
          className=" relative w-full h-full cursor-pointer border border-white border-b-0"
        >
          <img
            className="!relative w-full h-full object-cover"
            src="/images/quick5.png"
            alt="quick"
            width={260}
            height={95}
          />
        </div>
        <div
          onClick={openfunc}
          className=" relative w-full h-full cursor-pointer border border-white border-b-0"
        >
          <img
            className="!relative w-full h-full object-cover"
            src="/images/quick6.png"
            alt="quick"
            width={260}
            height={95}
          />
        </div>
      </div>
      <div className="grid grid-cols-3 lg:block">
        <div
          onClick={() => window.open("https://blog.naver.com/cooldud22")}
          className=" relative w-full h-full bg-[#30b14a] cursor-pointer border border-white"
        >
          <img
            className="!relative w-full h-full object-cover"
            src="/images/quick2.png"
            alt="quick"
            width={260}
            height={95}
          />
        </div>
        <div
          onClick={() => window.open("http://pf.kakao.com/_ixhRdn")}
          className=" relative w-full h-full bg-[#ffe600] cursor-pointer border border-white"
        >
          <img
            className="!relative w-full h-full object-cover"
            src="/images/quick3.png"
            alt="quick"
            width={260}
            height={95}
          />
        </div>
        <div
          onClick={() => window.open("https://talk.naver.com/ct/WRMBSL1")}
          className=" relative w-full h-full bg-[#00db39] cursor-pointer border border-white"
        >
          <img
            className="!relative w-full h-full object-cover"
            src="/images/quick4.png"
            alt="quick"
            width={260}
            height={95}
          />
        </div>
      </div>
      <ModalPop popupRef={popupRef} closefnc={closefunc} />
    </div>
  );
};

export default MobileQuick;
