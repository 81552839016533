const QnA = () => {
  return (
    <div
      id="qna"
      className="relative w-full h-full px-[calc((100%-var(--container))/2)] py-14 lg:py-28"
    >
      <div id="title" className=" relative w-full h-full text-center mb-10">
        <h1 className="font-medium leading-tight">고객님들께서</h1>
        <h1 className="gmarket leading-tight text-primary">
          자주하시는&nbsp;질문!
        </h1>
        <p className="text-lg lg:text-xl xl:text-2xl mt-2 xl:mt-3">
          200건&nbsp;이상의&nbsp;작업사례를&nbsp;보유한
          누수방수야에서&nbsp;해결해드립니다.
        </p>
      </div>
      <div className=" relative w-full h-full lg:max-w-screen-xl mx-auto grid lg:grid-rows-4 gap-8 2xl:gap-10">
        <div
          data-aos="fade-up"
          className=" relative w-full h-full lg:flex justify-start items-center gap-5"
        >
          <div className="relative w-20 h-20 lg:w-24 lg:h-24 mx-auto lg:mx-0 p-2 lg:p-4 rounded-full border border-primary grid items-center">
            <img
              className="!relative w-full h-auto object-contain"
              src="/images/qna1.png"
              alt="icon"
              width={157}
              height={102}
            />
          </div>
          <div className=" relative w-full h-full mt-3 lg:mt-0">
            <h3 className="relative text-center lg:text-left lg:pl-5 z-10">
              Q.언제나 긴급출동이 가능한가요?
            </h3>
            <div className=" relative w-full h-fit bg-[#fffedc] p-5 rounded-2xl lg:rounded-full -mt-4">
              <p className=" lg:text-base xl:text-lg 2xl:text-xl">
                특이사항이&nbsp;있는경우가&nbsp;아니라면{" "}
                <span className="text-[var(--red-color)] font-bold">
                  서울,&nbsp;경기,&nbsp;인천&nbsp;전지역&nbsp;긴급출동
                </span>
                &nbsp; 가능합니다.
              </p>
            </div>
          </div>
        </div>
        <div
          data-aos="fade-up"
          className=" relative w-full h-full lg:flex justify-start items-center gap-5"
        >
          <div className="relative w-20 h-20 lg:w-24 lg:h-24 mx-auto lg:mx-0 p-2 lg:p-4 rounded-full border border-primary grid items-center">
            <img
              className="!relative w-full h-auto object-contain"
              src="/images/qna2.png"
              alt="icon"
              width={122}
              height={122}
            />
          </div>
          <div className=" relative w-full h-full mt-3 lg:mt-0">
            <h3 className="relative text-center lg:text-left lg:pl-5 z-10">
              Q.미해결시 정말 0원인가요?
            </h3>
            <div className=" relative w-full h-fit bg-[#fffedc] p-5 rounded-2xl lg:rounded-full -mt-4">
              <p className=" lg:text-base xl:text-lg 2xl:text-xl">
                네!&nbsp;그렇습니다.&nbsp;누수방수야에서&nbsp;현장방문&nbsp;후
                누수에&nbsp;대한&nbsp;부분을
                <span className="text-[var(--red-color)] font-bold">
                  &nbsp; 해결하지&nbsp;못하면 비용은&nbsp;없습니다.
                </span>
              </p>
            </div>
          </div>
        </div>
        <div
          data-aos="fade-up"
          className=" relative w-full h-full lg:flex justify-start items-center gap-5"
        >
          <div className="relative w-20 h-20 lg:w-24 lg:h-24 mx-auto lg:mx-0 p-2 lg:p-4 rounded-full border border-primary grid items-center">
            <img
              className="!relative w-full h-auto object-contain"
              src="/images/qna3.png"
              alt="icon"
              width={135}
              height={115}
            />
          </div>
          <div className=" relative w-full h-full mt-3 lg:mt-0">
            <h3 className="relative text-center lg:text-left lg:pl-5 z-10">
              Q.보험에 대한 서류도 지원해주시나요?
            </h3>
            <div className=" relative w-full h-fit bg-[#fffedc] p-5 rounded-2xl">
              <p className=" lg:text-base xl:text-lg 2xl:text-xl">
                누수방수야에서는&nbsp;누수해결&nbsp;후&nbsp;누수소견서&nbsp;및&nbsp;각종{" "}
                <span className="text-[var(--red-color)] font-bold">
                  서류들을&nbsp;요청시&nbsp;무상으로
                </span>
                &nbsp;제공해드립니다.
              </p>
            </div>
          </div>
        </div>
        <div
          data-aos="fade-up"
          className=" relative w-full h-full lg:flex justify-start items-center gap-5"
        >
          <div className="relative w-20 h-20 lg:w-24 lg:h-24 mx-auto lg:mx-0 p-2 lg:p-4 rounded-full border border-primary grid items-center">
            <img
              className="!relative w-full h-auto object-contain"
              src="/images/qna4.png"
              alt="icon"
              width={129}
              height={129}
            />
          </div>
          <div className=" relative w-full h-full mt-3 lg:mt-0">
            <h3 className="relative text-center lg:text-left lg:pl-5 z-10">
              Q.누수해결 후 이후 공사도 진행가능한가요?
            </h3>
            <div className=" relative w-full h-fit bg-[#fffedc] p-5 rounded-2xl lg:rounded-full -mt-4">
              <p className=" lg:text-base xl:text-lg 2xl:text-xl">
                누수로&nbsp;인해&nbsp;발생한&nbsp;기타&nbsp;여러가지{" "}
                <span className="text-[var(--red-color)] font-bold">
                  피해에 대한 공사를 진행
                </span>
                &nbsp;할&nbsp;수&nbsp;있습니다.&nbsp;(별도 견적발생)
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QnA;
