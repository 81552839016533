import { IconX } from "@tabler/icons-react";
import Inquiry from "./Inquiry";

const ModalPop = ({
  popupRef,
  closefnc,
}: {
  popupRef: any;
  closefnc: () => void;
}) => {
  return (
    <div
      ref={popupRef}
      className="hidden fixed left-0 top-0 w-full h-full bg-black bg-opacity-70 z-50"
    >
      <div className="relative w-full h-full grid items-center">
        <div className="relative w-full max-w-[350px] lg:max-w-screen-md h-fit py-5 lg:py-8 max-h-screen bg-white rounded-2xl mx-auto">
          <button className="absolute w-6 h-6 lg:w-8 lg:h-8 top-2 right-2 text-main cursor-pointer">
            <IconX
              className="relative w-full h-full"
              onClick={closefnc}
              stroke={3}
            />
          </button>
          <div className="relative w-full h-fit mx-auto">
            <img
              className="!relative w-full h-full max-h-10 object-contain"
              alt="logo"
              src="/images/logo.png"
              width={514}
              height={132}
            />
          </div>
          <div className="relative w-full h-fit bg-primary text-white mt-5 flex justify-center items-center gap-5">
            <div className="relative w-[1px] h-8 bg-white"></div>
            <h5 className="bold pt-1">간편상담 바로가기</h5>
            <div className="relative w-[1px] h-8 bg-white"></div>
          </div>
          <div className="px-2 lg:px-5 text-left ">
            <Inquiry />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalPop;
