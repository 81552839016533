const Equipment = () => {
  const equipList = [
    {
      id: 1,
      src: "/images/equip1.png",
      name: "내시경 카메라",
    },
    {
      id: 2,
      src: "/images/equip2.png",
      name: "가스식 누수탐지",
      name2: "PRO-X5G1",
    },
    {
      id: 3,
      src: "/images/equip3.png",
      name: "청음식 누수탐지기",
      name2: "PRO-V3 POWER",
    },
    {
      id: 4,
      src: "/images/equip4.png",
      name: "청음식 옥외용 누수탐지기",
      name2: "외부탐사전용 DS-40A",
    },
    {
      id: 5,
      src: "/images/equip5.png",
      name: "아쿠아스코프3 프로",
    },
    {
      id: 6,
      src: "/images/equip6.png",
      name: "디지털압력계",
      name2: "DS-1035 구 DS-1030",
    },
    {
      id: 7,
      src: "/images/equip7.png",
      name: "청음식 누수 탐지기",
      name2: "DS-400A",
    },
    {
      id: 8,
      src: "/images/equip8.png",
      name: "플렌저펌프 핸디형",
      name2: "LAVOR 산업용고압세척기",
    },
    {
      id: 9,
      src: "/images/equip9.png",
      name: "플렉스샤프트",
      name2: "Flexshaft K9-12",
    },
    {
      id: 10,
      src: "/images/equip10.png",
      name: "건습식청소기",
      name2: "60L WD1685",
    },
    {
      id: 11,
      src: "/images/equip11.png",
      name: "구터만 가스누수탐지기",
      name2: "400GD",
    },
    {
      id: 12,
      src: "/images/equip12.png",
      name: "세버린 수소 가스탐지기 스누퍼",
    },
  ];
  return (
    <div
      id="equip"
      className="relative w-full h-full px-[calc((100%-var(--container))/2)] mx-auto py-14 lg:pb-28"
    >
      <div id="title" className="relative w-full h-fit text-center mb-10">
        <div className="relative w-full h-fit flex justify-center items-end flex-wrap sm:flex-nowrap">
          <div className="relative w-auto h-fit">
            <img
              className="!relative w-auto h-full max-h-7 lg:max-h-9 2xl:max-h-11 object-contain"
              alt="logo"
              src="/images/logo.png"
              width={430}
              height={104}
            />
          </div>
          <h2 className="leading-none gmedium">&nbsp;보유장비 현황</h2>
        </div>
        <p className="text-lg xl:text-xl mt-2 xl:mt-3 text-primary">
          - EQUIPMENT -
        </p>
      </div>
      <div className="relative w-full h-fit grid grid-cols-2 lg:grid-cols-4 gap-3 lg:gap-5">
        {equipList.map((v, i) => (
          <div
            key={i}
            className=" relative w-full h-full text-center mb-3 lg:mb-10"
          >
            <div className="relative w-fit mx-auto h-auto object-contain mb-1.5 lg:mb-3">
              <img src={v.src} alt="circle" width={357} height={357} />
            </div>
            <p className="xl:text-xl">{v.name}</p>
            <p className="xl:text-xl">{v.name2}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Equipment;
