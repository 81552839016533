const MobileQuick = () => {
  return (
    <div className="hidden xl:block fixed left-[calc((100%-var(--container))/5)] bottom-24 w-fit h-fit z-40">
      <div className=" relative w-full h-full max-w-[650px] ml-5 flex justify-start items-center gap-3">
        <div
          onClick={() => window.open("https://blog.naver.com/cooldud22")}
          className=" relative w-full h-full cursor-pointer hover:-translate-y-2 transition-transform duration-300"
        >
          <img
            className="!relative w-full h-full object-cover"
            src="/images/quick2.png"
            alt="quick"
            width={253}
            height={74}
          />
        </div>
        <div
          onClick={() => window.open("http://pf.kakao.com/_ixhRdn")}
          className=" relative w-full h-full cursor-pointer hover:-translate-y-2 transition-transform duration-300"
        >
          <img
            className="!relative w-full h-full object-cover"
            src="/images/quick3.png"
            alt="quick"
            width={253}
            height={74}
          />
        </div>
        <div
          onClick={() => window.open("https://talk.naver.com/ct/WRMBSL1")}
          className=" relative w-full h-full cursor-pointer hover:-translate-y-2 transition-transform duration-300"
        >
          <img
            className="!relative w-full h-full object-cover"
            src="/images/quick4.png"
            alt="quick"
            width={253}
            height={74}
          />
        </div>
      </div>
    </div>
  );
};

export default MobileQuick;
