const Service = () => {
  const serviceList = [
    {
      img: "/images/service1.png",
      txt: "누수탐지 전문",
      //   link: "https://blog.naver.com/PostList.naver?blogId=langlangshop&from=postList&categoryNo=32",
    },
    {
      img: "/images/service2.png",
      txt: "누수공사 전문",
      //   link: "https://blog.naver.com/PostList.naver?blogId=langlangshop&from=postList&categoryNo=33",
    },
    {
      img: "/images/service3.png",
      txt: "화장실누수 전문",
      //   link: "https://blog.naver.com/PostList.naver?blogId=langlangshop&from=postList&categoryNo=34",
    },
    {
      img: "/images/service4.png",
      txt: "방수공사 전문",
      //   link: "https://blog.naver.com/PostList.naver?blogId=langlangshop&from=postList&categoryNo=35",
    },
    {
      img: "/images/service5.png",
      txt: "배관누수 전문",
      //   link: "https://blog.naver.com/PostList.naver?blogId=langlangshop&from=postList&categoryNo=36",
    },
    {
      img: "/images/service6.png",
      txt: "아파트누수 전문",
      //   link: "https://blog.naver.com/PostList.naver?blogId=langlangshop&from=postList&categoryNo=37",
    },
  ];
  return (
    <div
      id="service"
      className="relative w-full h-full px-[calc((100%-var(--container))/2)] mx-auto py-14 lg:pb-28 xl:pb-48"
    >
      <div id="title" className="relative w-full h-fit text-center mb-10">
        <div className="relative w-full h-fit flex justify-center items-end flex-wrap sm:flex-nowrap">
          <div className="relative w-auto h-fit">
            <img
              className="!relative w-auto h-full max-h-7 lg:max-h-9 2xl:max-h-11 object-contain"
              alt="logo"
              src="/images/logo.png"
              width={430}
              height={104}
            />
          </div>
          <h2 className="leading-none gmedium">&nbsp;서비스 목록</h2>
        </div>
        <p className="text-lg xl:text-xl mt-2 xl:mt-3 text-primary">
          - SERVICE -
        </p>
        <p className="text-lg lg:text-xl xl:text-2xl mt-1">
          수도권&nbsp;전지역&nbsp;누수·방수&nbsp;고민은
          누수방수야로&nbsp;해결하세요!
        </p>
      </div>
      <div className="relative w-full h-fit grid grid-cols-2 lg:grid-cols-4 gap-3 lg:gap-5">
        {serviceList.map((v, i) => (
          <div
            key={i}
            data-aos="fade-up"
            // onClick={() => window.open(v.link)}
            className="relative w-full h-full bg-white p-3 border shadow-lg rounded-md cursor-pointer"
          >
            <div className="relative w-full h-full overflow-hidden">
              <img
                className="!relative w-full h-52 md:h-80 lg:h-60 xl:h-80 3xl:h-[550px] object-cover hover:scale-110 transition-transform duration-300"
                alt="service"
                src={v.img}
                width={328}
                height={355}
              />
            </div>
            <div className="absolute bottom-0 left-0 w-full h-fit bg-white text-center text-light pt-3 pb-2">
              <p className="lg:text-xl xl:text-2xl text-nowrap gmedium">
                {v.txt}
              </p>
            </div>
          </div>
        ))}
        <div className="col-span-2 relative w-full h-full grid justify-items-center items-center cursor-pointer">
          <img
            onClick={() => (window.location.href = "tel:010-8330-8913")}
            className="!relative w-full h-auto px-5 2xl:px-10"
            src="/images/service7.png"
            alt="service"
            width={576}
            height={575}
          />
        </div>
      </div>
    </div>
  );
};

export default Service;
