import { Outlet, useLocation } from "react-router-dom";
import MHeader from "./header/MHeader";
import MFooter from "./footer/MFooter";
import Quick from "./quick/Quick";
import QuickBar from "./quick/QuickBar";
import MobileQuick from "./quick/MobileQuick";
import AHeader from "./header/AHeader";
import AMHeader from "./header/AMHeader";

const Layout = () => {
  const { pathname } = useLocation();

  console.log(pathname);

  if (pathname.includes("login")) {
    return (
      <div className="relative w-full h-full max-w-[100vw] overflow-x-hidden">
        <Outlet />
      </div>
    );
  }
  if (pathname.includes("admin")) {
    if (!sessionStorage.getItem("email")) {
      window.location.href = "/admin/login";
      return <></>;
    }
    return (
      <div className="relative w-full h-full max-w-[100vw] overflow-x-hidden">
        <AMHeader />
        <AHeader />
        <div className="relative w-full h-full py-32 lg:py-36 px-2 lg:pl-72 lg:pr-[calc((100%-var(--container))/2)] max-w-[var(--container-width)] ">
          <Outlet />
        </div>
      </div>
    );
  }

  return (
    <div className="relative w-full h-full max-w-[100vw] overflow-x-hidden">
      <MHeader />
      <div className="relative w-full h-fit mt-20 lg:mt-28 xl:mt-32 2xl:mt-[140px] 3xl:mt-36 min-h-[50vh] lg:min-h-[60vh]">
        <Outlet />
      </div>
      <Quick />
      <MobileQuick />
      <QuickBar />
      <MFooter />
    </div>
  );
};

export default Layout;
