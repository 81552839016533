import { Link } from "react-router-dom";

const MFooter = () => {
  const menuOnclick = (link: string) => {
    let menuHeight =
      document.querySelector<HTMLDivElement>("#header")!.offsetHeight;

    var location = document.querySelector<HTMLDivElement>(link)!.offsetTop;

    window &&
      window.scrollTo({
        top: location - menuHeight,
        behavior: "smooth",
      });
  };
  return (
    <footer className="z-0 relative w-full h-full px-[calc((100%-var(--container))/2)] bg-[#181818] text-white pb-14 xl:pb-16">
      <div className="relative w-full h-fit max-w-[var(--container-width)] mx-auto py-16">
        <div className=" relative w-fit h-full mx-auto">
          <img
            alt="logo"
            src={"/images/white_logo.png"}
            className=" relative w-auto h-full max-h-5 lg:max-h-8 object-contain"
          />
        </div>
        <div className=" relative w-full h-fit flex justify-center items-center gap-7 my-10">
          <span
            onClick={() => menuOnclick("#about")}
            className="opacity-70 hover:opacity-100"
          >
            <small>회사소개</small>
          </span>
          <small>|</small>
          <span
            onClick={() => menuOnclick("#service")}
            className="opacity-70 hover:opacity-100"
          >
            <small>서비스목록</small>
          </span>
          <small>|</small>
          <span
            onClick={() => menuOnclick("#blog")}
            className="opacity-70 hover:opacity-100"
          >
            <small>작업사례</small>
          </span>
          <small>|</small>
          <span
            onClick={() => menuOnclick("#contact")}
            className="opacity-70 hover:opacity-100"
          >
            <small>온라인문의</small>
          </span>
        </div>
        <div className="flex justify-center items-center gap-2 mb-2 lg:mb-0 lg:gap-5 flex-wrap lg:flex-nowrap opacity-90">
          <small className="font-bold">누수야&nbsp;종합하자보수</small>
          <small>|</small>
          <small>대표자: 안정용</small>
          <small>|</small>
          <small>전화: 010-8330-8913</small>
          <small>|</small>
          <small>사업자등록번호: 873-36-00580</small>
        </div>
        <div className="flex justify-center items-center gap-2 lg:gap-5 flex-wrap lg:flex-nowrap mb-14 opacity-90">
          {/* <small>|</small> */}
          <small>주소: 서울특별시 강북구 도봉로77길 27-10</small>
        </div>
        <div className="lg:flex justify-center items-center gap-2 text-center lg:text-left opacity-90">
          <small>
            COPYRIGHT ⓒ 누수방수야. <br className="block lg:hidden" /> ALL
            RIGHTS RESERVED. DESIGN BY [SMILEEAN]
          </small>
          <br className="block lg:hidden" />
          <Link
            to={"/admin/login"}
            className=" relative w-fit h-fit text-xs py-0.5 px-3 mt-2 lg:mt-0 border rounded-md hover:bg-white hover:text-black"
          >
            <small>관리자</small>
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default MFooter;
