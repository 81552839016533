import { dateFormat } from "../../const/date";

const Blog = ({ data }: { data: any[] }) => {
  const blog = ParseData({ data });

  return (
    <div
      id="blog"
      className="relative w-full h-full py-14 lg:py-28 bg-[#0147C2] px-[calc((100%-var(--container))/2)]"
    >
      <div
        id="title"
        className="relative w-full h-fit text-center text-white mb-10"
      >
        <div className="relative w-full h-fit flex justify-center items-end flex-wrap sm:flex-nowrap">
          <div className="relative w-auto h-fit">
            <img
              className="!relative w-auto h-full max-h-7 lg:max-h-9 2xl:max-h-11 object-contain"
              alt="logo"
              src="/images/white_logo.png"
              width={430}
              height={104}
            />
          </div>
          <h2 className="leading-none gmedium">&nbsp;BLOG&nbsp;작업사례</h2>
        </div>
        <p className="text-lg xl:text-xl mt-2 xl:mt-3">- BLOG -</p>
        <p className="text-lg lg:text-xl xl:text-2xl mt-1">
          200건이&nbsp;넘는&nbsp;누수공사&nbsp;작업사례!
          지금&nbsp;바로&nbsp;확인해보세요
        </p>
      </div>
      <div className=" relative w-full h-full">
        <div className=" relative w-full h-full my-3 lg:my-8 xl:my-14 grid grid-cols-2 lg:grid-cols-4 gap-3 lg:gap-5">
          <img src="/images/blog1.png" alt="icon" width={471} height={785} />
          <img src="/images/blog2.png" alt="icon" width={471} height={785} />
          <img src="/images/blog3.png" alt="icon" width={471} height={785} />
          <img src="/images/blog4.png" alt="icon" width={471} height={785} />
        </div>
        <div
          id="title"
          className="relative w-full h-fit text-center text-white py-10"
        >
          <h2 className="gmedium">어떤 현장! 어떤 누수라도!</h2>
          <h2 className="leading-10 gmedium">
            맡겨만 주시면 <span className="text-point">자신있게&nbsp;해결</span>
            해드리겠습니다!
          </h2>
        </div>
        <div className="relative w-full h-fit xl:col-span-2 grid grid-cols-2 xl:grid-cols-4 gap-2 2xl:gap-3 lg:px-10 mb-10">
          {blog &&
            blog.map((v: any, i: number) => (
              <div
                key={i}
                onClick={() => window.open(v.link[0])}
                className="relative w-full h-full"
              >
                <div className="relative w-full h-full grid bg-white p-2 md:p-3 rounded-3xl text-left border hover:border-[var(--main-color)] cursor-pointer transition-all duration-300 overflow-hidden">
                  <p
                    className={`relative w-full whitespace-nowrap overflow-hidden overflow-ellipsis lg:text-base h-fit mb-1`}
                  >
                    {v.title[0]}
                  </p>
                  <p
                    className={`text_line h-8 break-words line-clamp-2 text-xs opacity-60`}
                  >
                    {v.description[0]}
                  </p>
                  <div className="relative w-full pt-1 md:pt-2 flex justify-between items-end">
                    <small className="opacity-40 lg:text-sm txt_line">
                      {dateFormat(v.pubDate[0])}
                    </small>
                    <small className="lg:text-sm txt_line text-right text-primary">
                      {v.category[0]}
                    </small>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

const parseString = require("xml2js").parseString;

const ParseData = ({ data }: { data: any[] }) => {
  const dataArr: {
    title: any;
    link: any;
    description: any;
    pubDate: any;
    category: any;
  }[] = [];

  data.length > 0 &&
    parseString(data, (e: any, data: any) => {
      // eslint-disable-next-line
      data.rss.channel[0].item.map((v: any, i: any) => {
        i < 16 &&
          dataArr.push({
            title: v.title,
            link: v.link,
            description: v.description,
            pubDate: v.pubDate,
            category: v.category,
          });
      });
    });

  return dataArr;
};

export default Blog;
